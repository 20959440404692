<template>
  <th-wrapper
    :title="$t('pages.settings.ui_configurations.dashboard.products.title')"
    :info="$t('pages.settings.ui_configurations.dashboard.products.tooltip')"
  >
    <!-- Quick view -->
    <div class="mb-4">
      <el-switch
        v-model="dashboardProductsQuickViewEnabled"
        :active-text="dashboardProductsQuickViewEnabledMessage"
      />
    </div>

    <!-- Product prices in edit mode -->
    <div>
      <el-switch
        v-model="dashboardProductsEditPricesClickToEditEnabled"
        :active-text="dashboardProductsEditPricesClickToEditEnabledMessage"
      />
    </div>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  computed: {
    dashboardProductsQuickViewEnabledMessage() {
      return this.dashboardProductsQuickViewEnabled
        ? this.$t(
            'pages.settings.ui_configurations.dashboard.products.list.quick_view_enabled.switch_enabled'
          )
        : this.$t(
            'pages.settings.ui_configurations.dashboard.products.list.quick_view_enabled.switch_disabled'
          )
    },
    dashboardProductsQuickViewEnabled: {
      get() {
        return (
          safeGet(
            this.$store.getters['Config/getClientAccountConfiguration'],
            'ui_configurations.dashboard.products.list.quick_view_enabled'
          ) !== false
        )
      },
      set(isEnabled) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'ui_configurations.dashboard.products.list.quick_view_enabled',
          value: !!isEnabled
        })
      }
    },
    dashboardProductsEditPricesClickToEditEnabledMessage() {
      return this.dashboardProductsEditPricesClickToEditEnabled
        ? this.$t(
            'pages.settings.ui_configurations.dashboard.products.list.click_to_edit_enabled.switch_enabled'
          )
        : this.$t(
            'pages.settings.ui_configurations.dashboard.products.list.click_to_edit_enabled.switch_disabled'
          )
    },
    dashboardProductsEditPricesClickToEditEnabled: {
      get() {
        return (
          safeGet(
            this.$store.getters['Config/getClientAccountConfiguration'],
            'ui_configurations.dashboard.products.edit.prices.click_to_edit_enabled'
          ) || false
        )
      },
      set(isEnabled) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path:
            'ui_configurations.dashboard.products.edit.prices.click_to_edit_enabled',
          value: !!isEnabled
        })
      }
    }
  }
}
</script>
