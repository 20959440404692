<template>
  <th-wrapper
    :title="$t('pages.settings.products.product_id_generator.title')"
    :info="$t('pages.settings.products.product_id_generator.instructions')"
  >
    <!-- Enable -->
    <div class="mb-4">
      <el-switch
        v-model="productIdGeneratorConfig"
        :active-text="productIdGeneratorConfigSwitchLabel"
      />
    </div>

    <!-- Strict mode -->
    <div class="mb-4">
      <el-switch
        v-model="strictModeConfig"
        :active-text="strictModeConfigSwitchLabel"
        :disabled="!productIdGeneratorConfig"
      />
    </div>

    <!-- Auto id generator -->
    <auto-id-generator
      ref="auto-id-generator"
      :disabled="!productIdGeneratorConfig"
      :config-path="templatePath"
      :wanted-elements="elements"
    />
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import AutoIdGenerator from '../../../components/auto-id-generator'

export default {
  components: {
    AutoIdGenerator
  },
  data() {
    return {
      showMessage: true,
      templatePath: 'products.product_id_template',
      switchPath: 'products.generate_product_id',
      strictModePath: 'products.strict_mode',
      elements: [
        'full_date',
        'year',
        'month',
        'day',
        'country',
        'text',
        'dash',
        'product_group_number',
        'sequence'
      ]
    }
  },
  computed: {
    productIdGeneratorConfig: {
      get() {
        return get(
          this.$store.state.Config.clientAccountConfiguration,
          this.switchPath
        )
      },
      set(isEnabled) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: this.switchPath,
          value: isEnabled
        })

        if (!isEnabled) {
          this.strictModeConfig = false
        }
      }
    },
    strictModeConfig: {
      get() {
        return get(
          this.$store.state.Config.clientAccountConfiguration,
          this.strictModePath
        )
      },
      set(isEnabled) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: this.strictModePath,
          value: isEnabled
        })
      }
    },
    productIdGeneratorConfigSwitchLabel() {
      return this.productIdGeneratorConfig
        ? this.$t('pages.settings.products.product_id_generator.switch.enabled')
        : this.$t(
            'pages.settings.products.product_id_generator.switch.disabled'
          )
    },
    strictModeConfigSwitchLabel() {
      return this.strictModeConfig
        ? this.$t(
            'pages.settings.products.product_id_generator.strict_mode.enabled'
          )
        : this.$t(
            'pages.settings.products.product_id_generator.strict_mode.disabled'
          )
    }
  },
  methods: {
    validate() {
      return this.$refs['auto-id-generator'].validate()
    }
  }
}
</script>
