<template>
  <section>
    <product-id-generator ref="product-id-generator" />
    <dashboard-product-variants />
    <pricebooks />
    <dashboard-products />
  </section>
</template>

<script>
import ProductIdGenerator from './components/product-id-generator'
import Pricebooks from './components/pricebooks'
import DashboardProducts from './components/dashboard-products'
import DashboardProductVariants from './components/dashboard-product-variants'

export default {
  components: {
    ProductIdGenerator,
    Pricebooks,
    DashboardProducts,
    DashboardProductVariants
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.products.title')
    }
  },
  methods: {
    async validate(cb) {
      const productIdGeneratorValid = await this.$refs[
        'product-id-generator'
      ].validate()

      cb(productIdGeneratorValid)
    }
  }
}
</script>
