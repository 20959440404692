<template>
  <th-wrapper :title="$t('pages.settings.dashboard.products.pricebooks.title')">
    <th-input-title
      id="prices_conflict"
      :title="
        $t('pages.settings.dashboard.products.pricebooks.prices_conflict.title')
      "
    />

    <el-radio-group
      v-model="pricesConflict"
      aria-labelledby="prices_conflict"
      class="flex flex-col mt-2"
    >
      <el-radio label="lowest_price">
        {{
          $t(
            'pages.settings.dashboard.products.pricebooks.prices_conflict.options.lowest_price'
          )
        }}
      </el-radio>
      <el-radio label="highest_price">
        {{
          $t(
            'pages.settings.dashboard.products.pricebooks.prices_conflict.options.highest_price'
          )
        }}
      </el-radio>
      <el-radio label="user">
        {{
          $t(
            'pages.settings.dashboard.products.pricebooks.prices_conflict.options.user'
          )
        }}
      </el-radio>
    </el-radio-group>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    pricesConflict: {
      get() {
        return (
          get(
            this.$store.getters['Config/getClientAccountConfiguration'],
            'products.pricebook.selection_type'
          ) ?? 'lowest_price'
        )
      },
      set(value) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'products.pricebook.selection_type',
          value: value ?? 'lowest_price'
        })
      }
    }
  }
}
</script>
