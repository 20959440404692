<template>
  <th-wrapper
    :title="
      $t(
        'pages.settings.ui_configurations.dashboard.products.attributes_in_name.title'
      )
    "
    :info="
      $t(
        'pages.settings.ui_configurations.dashboard.products.attributes_in_name.tooltip'
      )
    "
  >
    <!-- Include variants attributes in the variant product name -->
    <div class="mb-4">
      <el-switch
        v-model="attributesInName"
        :active-text="attributesInNameEnabledMessage"
      />
    </div>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  computed: {
    attributesInNameEnabledMessage() {
      return this.attributesInName
        ? this.$t(
            'pages.settings.ui_configurations.dashboard.products.attributes_in_name.switch_enabled'
          )
        : this.$t(
            'pages.settings.ui_configurations.dashboard.products.attributes_in_name.switch_disabled'
          )
    },
    attributesInName: {
      get() {
        return (
          safeGet(
            this.$store.getters['Config/getClientAccountConfiguration'],
            'ui_configurations.dashboard.products.attributes_in_name'
          ) !== false
        )
      },
      set(isEnabled) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'ui_configurations.dashboard.products.attributes_in_name',
          value: !!isEnabled
        })
      }
    }
  }
}
</script>
